import styled from 'styled-components'
import { gray, orange, white } from '../../../styles/colors'
import { borderRounded } from '../../../styles/radius'
import breakpoints from '../../../styles/breakpoints'

export const Contato = styled.div`

  .form-solicite-contato {
    @media (min-width: ${breakpoints.md}) {
      padding: 60px 100px;
    }
    .center {
      text-align: center;
    }
    
    .form-hooks > .body-form {
      display: flex;
      flex-wrap: wrap;

      input, textarea {
        background: ${gray['400']};
        border-radius: ${borderRounded['4']};
        border: 0;
        font-size: 14px;
        font-weight: 300;
        line-height: 17px;
        padding: 0 10px;
        color: #8A96A8;
        height: 40px;       
      }

      textarea {
        min-height: 114px;
        line-height: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
      }

      .hasError {
        color: red;
      }

      .formLabelCheck {
        position: relative;
        margin-left: 8px;
        padding-left: 10px;

        &::before, &::after {
          display: block;
          position: absolute;
          content: '';
        }

        &::before {
          border: 2px solid ${gray['500']};
          width: 20px;
          height: 20px;
          left: -30px;
          top: 10px;
          border-radius: ${borderRounded['3']};

          @media (min-width: ${breakpoints.md}) {
            top: 50%;
            transform: translateY(-50%);
          }
        }

        button {
          color: #FF7A00;
        }
      }

      .formInputCheck {
        width: auto;
        margin-right: 10px;
        opacity: 0;
        height: 0;
        &:disabled ~ label {
        opacity: 0.6;
        }
        &:checked ~ label {
          &::before {
            border: 2px solid ${orange.base};
            background-color: ${orange.base};
          }
          &::after {
            left: -23px;
            top: 13px;
            width: 6px;
            height: 10px;
            border: solid ${white};
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);

            @media (min-width: ${breakpoints.md}) {
              top: 50%;
              transform: translateY(-50%) rotate(45deg);
            }
          }
        }
      }

      button {
        &:hover {
          border: 1px solid ${orange.base};
          background: ${orange.base};
        }
      }  
    }
  }
`
