import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { OuterContainer, Overlay, ModalContainer, CloseButton } from './style'
import Icon from '../Icon'

interface IModal {
  children: React.ReactNode;
  visible?: boolean;
  hideCloseButton?: boolean;
  onClose?: () => void;
  onClickOutside?: () => void;
  className?: string;
  small?: boolean;
  opaqueOverlay?: boolean;
}

const Modal: React.FC<IModal> = ({ children, visible, hideCloseButton, onClose, onClickOutside, className, small, opaqueOverlay }: IModal) => {
  /**
   * Esta variável de estado é requerida para garantir que somente tentaremos
   * renderizar nosso modal, que utiliza portals, quando o DOM estiver pronto
   * para isto, visto que faremos uma pesquisa do tipo document.getElementById
   */
  const [ domReady, setDomReady ] = useState(false)

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (e.target === e.currentTarget && onClickOutside) onClickOutside()
  }

  useEffect(() => {
    setDomReady(true)
  }, [])

  return domReady ? createPortal((
    <OuterContainer visible={visible === undefined ? true : visible}>
      <Overlay onClick={handleOverlayClick} opaqueOverlay={!!opaqueOverlay} />
      <ModalContainer className={className} small={!!small}>
        {!hideCloseButton && (
          <CloseButton type='button' onClick={onClose}>
            <Icon height={20} width={20} directory='v2' icon='navigation/close' color='orange-base' />
          </CloseButton>
        )}
        {children}
      </ModalContainer>
    </OuterContainer>), document.getElementById('modal-area') as HTMLElement) : null
}

export default Modal
