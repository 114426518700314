import styled from 'styled-components'
import { gray } from '../../styles/colors'
import breakpoints from '../../styles/breakpoints'

interface IOuterContainer { visible: boolean }
interface IOverlay { opaqueOverlay: boolean }
interface IModalContainer { small: boolean }

export const OuterContainer = styled.div<IOuterContainer>`
  align-items: center;
  display: ${(props: IOuterContainer): string => props.visible ? 'flex' : 'none'};
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 998;
`
export const Overlay = styled.div<IOverlay>`
  background: #4B4E5C;
  height: 100%;
  opacity: ${(props: IOverlay): number => props.opaqueOverlay ? 1 : 0.8};
  position: fixed;
  width: 100%;
`
export const ModalContainer = styled.div<IModalContainer>`
  background: white;
  border-radius: 8px;
  border: 1px solid #E6E7F0;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  color: ${gray[700]};
  max-height: 90vh;
  max-width: ${(props: IModalContainer): string => props.small ? '530px' : '602px'};
  min-height: 484px;
  overflow: auto;
  padding: 38px 15px 30px 15px;
  position: relative;
  width: 90%;
  word-wrap: break-word;
  z-index: 999;
  display: flex;

  @media (min-width: ${breakpoints.md}) {
    align-items: center;
  }

  &.privacy-policy {
    align-items: initial;

    h3 {
      color: ${gray['800']};
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 16px;
    }

    h4 {
      color: ${gray['700']};
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 2px;
    }

    p {
      color: ${gray['600']};
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
    }
    
    @media (min-width: ${breakpoints.md}) {
      max-width: 990px;
    }
  }
`
export const CloseButton = styled.button`
  right: 20px;
  background: none;
  border: none;
  display: flex;
  height: 20px;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 22px;
  z-index: 10;
`
