import styled from 'styled-components'
import { orange, white, linear } from './colors'
import { borderRounded } from './radius'

export const Button = styled.button`
  border-radius: ${borderRounded['4']};
  border: 1px solid ${orange.medium};
  font-weight: bold;

  &:hover {
    background: ${orange.base};
    color: ${orange.base};
    opacity: 0.9;
  }

  &.inverse {
    background: ${linear.orange};
    border: none;
    color: ${white};
    min-width: 220px;
    padding: 10px 16px;   
  }
`
