import React, { useState, ChangeEvent } from 'react'
import axios from 'axios'
import queryString from 'query-string'
import { useForm } from 'react-hook-form'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLs from '../../../config/api/Urls'

// Components
import Message from './Message'
import OpenModal from '../../OpenModal'
import useOrigin from '../../../hooks/useOrigin'

// Styles
import { Contato } from './style'

const OmbudsmanForm = () => {
  const { register, errors, handleSubmit }: Pick<ReturnType<typeof useForm>, 'register' | 'errors' | 'handleSubmit' | 'setValue'> = useForm()
  const [ loading, setLoading ] = useState(false)
  const [ status, setStatus ] = useState('')
  const [ aceite, setAceite ] = useState(false)
  const origin = useOrigin()

  const sendForm = async (data: Record<string, string>) => {
    setLoading(true)
    const formData = {
      ...data,
      submit: 'ENVIAR DADOS',
    }
    try {
      await axios.post(URLs.CONTACT_FORM_POST_V3, queryString.stringify(formData), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      setLoading(false)
      setStatus('success')
    } catch (e) {
      setLoading(false)
      setStatus('error')
    }
  }

  function handleCheck (event: ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    setAceite(inputValue)
  }

  return (
    <>
      {
        status
          ? (
            <Message status={status} setStatus={setStatus} />
          )
          : (
            <Contato>
              <div className='col-12 form-solicite-contato'>
                <div className='col-12 mb-4 mb-md-4 pl-0'>
                  <h3 className='fs-20 lh-24 fw-600 text-gray-800 center'>
                    Estamos aqui para te atender
                  </h3>
                  <p className='fs-16 lh-22 text-gray-600 center'>
                    Com este canal, reforçamos nosso compromisso em estabelecer um processo transparente.
                    Em casos de dúvidas, reclamações e sugestões preencha o formulário abaixo.
                  </p>
                </div>
                <form
                  name='subscribeForm'
                  className='solicite-contato form-hooks'
                  onSubmit={handleSubmit(sendForm)}
                >
                  <div className='body-form'>
                    <div className='col-12 pb-2 d-flex flex-column px-0'>
                      <h3 className='fs-20 lh-24 text-gray-800 fw-600 pb-1'>Faça sua denúncia</h3>
                      <input ref={register()} type='hidden' name='_clientID' value='515008387' />
                      <input ref={register()} type='hidden' name='_deExternalKey' value='3B16749F-C66C-4416-80DB-03159D7C003C' />
                      <input ref={register()} type='hidden' name='_action' value='add' />
                      <input ref={register()} type='hidden' name='_returnXML' value='0' />
                      <input ref={register()} type='hidden' name='_successURL' value={`${origin}/success.json`} />
                      <input ref={register()} type='hidden' name='_errorURL' value={`${origin}/error.json`} />
                      <input ref={register()} type='hidden' name='EnviarPara' value='ri@interasset.com.br' />
                      <label htmlFor='Nome' className='fs-12 lh-15 text-gray-700 fw-700 pb-1'>Nome (Opcional)</label>
                      <input
                        ref={register}
                        name='Nome'
                        id='Nome'
                        type='text'
                        maxLength={80}
                      />
                    </div>
                    <div className='col-12 pb-2 d-flex flex-column mt-2 px-0'>
                      <label htmlFor='Email' className='fs-12 lh-15 text-gray-700 fw-700 pb-1'>E-mail (Opcional)</label>
                      <input
                        ref={register({
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'E-mail inválido',
                          },
                        })}
                        name='Email'
                        id='Email'
                        maxLength={80}
                        type='text'
                      />
                      { errors.Email && <p className='fs-12 mt-1 mb-0 hasError'>{errors.Email.message}</p> }
                    </div>
                    <div className='col-12 pb-2 d-flex flex-column mt-2 px-0'>
                      <label htmlFor='TituloDenuncia' className='fs-12 lh-15 text-gray-700 fw-700 pb-1'>Assunto</label>
                      <input
                        ref={register({
                          required: 'Título obrigatório',
                          validate: {
                            isName: (value: string) => Validations.name(value) || 'Por favor, digite um assunto mais detalhado',
                          },
                        })}
                        name='TituloDenuncia'
                        id='TituloDenuncia'
                        type='text'
                        maxLength={80}
                      />
                      { errors.TituloDenuncia && <p className='fs-12 mt-1 mb-0 hasError'>{errors.TituloDenuncia.message}</p> }
                    </div>
                    <div className='col-12 pb-3 d-flex flex-column mt-2 px-0'>
                      <label htmlFor='Denuncia' className='fs-12 lh-15 text-gray-700 fw-700 pb-1'>Mensagem</label>
                      <textarea
                        ref={register({
                          required: 'Campo mensagem obrigatório',
                        },
                        )}
                        name='Denuncia'
                        id='Denuncia'
                        maxLength={500}
                      />
                      { errors.Denuncia && <p className='fs-12 mt-1 mb-0 hasError'>{errors.Denuncia.message}</p> }
                    </div>
                    <div className='col-12 d-flex mb-2 pl-0 align-items-center'>
                      <input
                        className='formInputCheck'
                        id='mainForm'
                        name='mainForm'
                        type='checkbox'
                        onChange={handleCheck}
                      />
                      <label className='formLabelCheck fs-12 lh-16 text-gray-800' htmlFor='mainForm'>
                        Estou de acordo com a Política de Privacidade <OpenModal className='privacy-policy' />.
                      </label>
                    </div>
                    <div className='col-12 mt-2 mb-4 mb-md-0 px-0'>
                      <button
                        type='submit'
                        className='btn btn-lg text-white bg-orange-base text-uppercase fw-600 fs-16 w-100'
                        title='Enviar' disabled={loading || !aceite}
                      >
                        {loading ? 'Enviando...' : 'Enviar'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Contato>
          )
      }
    </>
  )
}

export default OmbudsmanForm
