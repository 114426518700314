import React, { useState, useEffect } from 'react'
import axios from 'axios'

const PrivacyPolicy: React.FC = () => {
  const [ loading, setLoading ] = useState(true)
  const [ bodyHtml, setBodyHtml ] = useState('')

  useEffect(() => {
    async function getHtml () {
      try {
        const response = await axios.get('https://static.bancointer.com.br/politica-de-privacidade.html')
        setBodyHtml(response.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    }
    getHtml()
  }, [])

  return (
    <div>
      { loading ? 'Carregando arquivo...' : <div dangerouslySetInnerHTML={{ __html: bodyHtml }} />}
    </div>
  )
}

export default PrivacyPolicy
