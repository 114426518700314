import { useEffect, useState } from 'react'

const useOrigin = (): string => {
  const [origin, setOrigin] = useState('')
  useEffect(() => {
    setOrigin(window.location.origin)
  }, [])
  return origin
}

export default useOrigin
