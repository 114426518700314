import React, { useState } from 'react'

// Components
import Modal from '../Modal'
import OmbudsmanForm from '../Forms/OmbudsmanForm'
import PrivacyPolicy from '../PrivacyPolicy/'

interface IOpenModal {
  type?: string;
  className?: string;
}

const OpenModal: React.FC<IOpenModal> = ({ type, className }: IOpenModal) => {
  const [ visible, setVisible ] = useState(false)
  let content = <></>

  function toggleVisible () {
    setVisible(!visible)
  }

  if (type === 'OmbudsmanModal') {
    content = <OmbudsmanForm />
  } else {
    content = <PrivacyPolicy />
  }

  return (
    <>
      {type === 'OmbudsmanModal'
        ? <button onClick={toggleVisible} className='text-brown-base fs-16 fs-lg-20 fw-600 d-block border-0 bg-transparent mx-auto'>Enviar contato</button>
        : <button type='button' onClick={toggleVisible} className='fw-700 fs-14 lh-20 fs-md-16 lh-md-20 border-0 text-orange-medium bg-transparent px-0'>Política de Privacidade do Inter</button> }
      <Modal visible={visible} onClickOutside={toggleVisible} onClose={toggleVisible} className={className}>
        {content}
      </Modal>
    </>
  )
}

export default OpenModal
