/* eslint-disable react/jsx-handler-names */
import React from 'react'

// Styles
import { Button } from '../../../styles/button'

interface IMessageProps {
  status: string;
  setStatus: (value: string) => void;
}

interface IInfo {
  [params: string]: {
    title: string;
    description: string;
    button: string;
    callbackButton: () => void;
   };
}

const Message = ({ status, setStatus }: IMessageProps) => {
  const info: IInfo = {
    success: {
      title: 'Recebemos sua mensagem',
      description: 'As informações serão tratadas com estrita confidencialidade e imparcialidade pela área de Compliance. ',
      button: 'Ok, entendi',
      callbackButton: () => setStatus(''),
    },
    error: {
      title: 'Mensagem não concluída',
      description: 'No momento, não foi possível concluir sua mensagem. Por favor, tente novamente.',
      button: 'Tentar novamente',
      callbackButton: () => setStatus(''),
    },
  }

  return (
    <div className='col-10 mx-auto text-center mt-5 mt-md-0 pt-3 pt-md-0'>
      <h4 className='fs-20 lh-24 mb-2 fw-600 text-gray-800'>{info[status].title}</h4>
      <p className='fs-16 lh-20 pt-3 mb-4 fw-200 text-gray-600'>
        {info[status].description}
      </p>
      <Button
        className='btn btn-lg text-uppercase mt-2 fw-600 fs-16 lh-20 inverse'
        title={info[status].button}
        onClick={info[status].callbackButton}
      >{info[status].button}
      </Button>
    </div>
  )
}

export default Message
